import { Injectable } from "@angular/core";
import createAuth0Client from "@auth0/auth0-spa-js";
import Auth0Client from "@auth0/auth0-spa-js/dist/typings/Auth0Client";
import { AUTH_CONFIG } from "./auth0.variables";
import {
  from,
  of,
  Observable,
  BehaviorSubject,
  combineLatest,
  throwError,
} from "rxjs";
import { tap, catchError, concatMap, shareReplay } from "rxjs/operators";
import { Router } from "@angular/router";
import { User } from "./common/User.model";
import { Url } from "url";
import { CookieService } from "ngx-cookie-service";

@Injectable({
  providedIn: "root",
})
export class AuthService {
  constructor(private router: Router, private cookie: CookieService) {
    // On initial load, check authentication state with authorization server
    // Set up local auth streams if user is already authenticated
    this.localAuthSetup();
    // Handle redirect from Auth0 login
    this.handleAuthCallback();
  }
  // Create an observable of Auth0 instance of client
  userName: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  userLoggedIn: BehaviorSubject<boolean> = new BehaviorSubject<boolean>(false);
  userName$: Observable<string> = this.userName.asObservable();
  userLoggedIn$: Observable<boolean> = this.userLoggedIn.asObservable();
  userImage: BehaviorSubject<Url> = new BehaviorSubject<Url>(null);
  userImage$: Observable<Url> = this.userImage.asObservable();
  accessToken: BehaviorSubject<string> = new BehaviorSubject<string>(null);
  accessToken$: Observable<string> = this.accessToken.asObservable();

  auth0Client$ = (
    from(
      createAuth0Client({
        domain: AUTH_CONFIG.domain,
        client_id: AUTH_CONFIG.clientID,
        redirect_uri: AUTH_CONFIG.callbackURL,
      })
    ) as Observable<Auth0Client>
  ).pipe(
    shareReplay(1), // Every subscription receives the same shared value
    catchError((err) => throwError(err))
  );
  // Define observables for SDK methods that return promises by default
  // For each Auth0 SDK method, first ensure the client instance is ready
  // concatMap: Using the client instance, call SDK method; SDK returns a promise
  // from: Convert that resulting promise into an observable
  isAuthenticated$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.isAuthenticated())),
    tap((res) => (this.loggedIn = res))
  );
  handleRedirectCallback$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.handleRedirectCallback()))
  );
  // Create subject and public observable of user profile data
  private userProfileSubject$ = new BehaviorSubject<any>(null);
  userProfile$ = this.userProfileSubject$.asObservable();
  // Create a local property for login status
  loggedIn: boolean = null;
  token: any = null;
  token$ = this.auth0Client$.pipe(
    concatMap((client: Auth0Client) => from(client.getIdTokenClaims())),
    tap((res) => {
      console.log("res", res);
      this.accessToken.next(res.__raw);
      this.cookie.set("accessToken", res.__raw);
      sessionStorage.setItem("accessToken", res.__raw);
      return (this.token = res);
    })
  );

  // When calling, options can be passed if desired
  // https://auth0.github.io/auth0-spa-js/classes/auth0client.html#getuser
  getUser$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getUser(options))),
      tap((user) => {
        this.userName.next(user.name);
        this.userLoggedIn.next(true);
        this.userImage.next(user.picture);
        sessionStorage.setItem("userName", user.name);
        sessionStorage.setItem("userImage", user.picture);
        this.cookie.set("userName", user.name);
        this.cookie.set("userImage", user.picture);
        this.userProfileSubject$.next(user);
      })
    );
  }

  private localAuthSetup() {
    // This should only be called on app initialization
    // Set up local authentication streams
    const checkAuth$ = this.isAuthenticated$.pipe(
      concatMap((loggedIn: boolean) => {
        if (loggedIn) {
          // If authenticated, get user and set in app
          // NOTE: you could pass options here if needed
          return this.getUser$();
        }
        // If not authenticated, return stream that emits 'false'
        return of(loggedIn);
      })
    );
    checkAuth$.subscribe();
  }

  login(redirectPath: string = "/dashboard") {
    // A desired redirect path can be passed to login method
    // (e.g., from a route guard)
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log in
      client.loginWithRedirect({
        redirect_uri: AUTH_CONFIG.callbackURL,
        appState: { target: redirectPath },
        connection: "main-tenant-oidc",
      });
    });
  }

  getTokenSilently$(options?): Observable<any> {
    return this.auth0Client$.pipe(
      concatMap((client: Auth0Client) => from(client.getTokenSilently(options)))
    );
  }

  private handleAuthCallback() {
    // Call when app reloads after user logs in with Auth0
    const params = window.location.search;
    if (params.includes("code=") && params.includes("state=")) {
      let targetRoute: string; // Path to redirect to after login processsed
      const authComplete$ = this.handleRedirectCallback$.pipe(
        // Have client, now call method to handle auth callback redirect
        tap((cbRes) => {
          // Get and set target redirect route from callback results
          targetRoute =
            cbRes.appState && cbRes.appState.target
              ? cbRes.appState.target
              : "/";
        }),
        concatMap(() => {
          // Redirect callback complete; get user and login status
          return combineLatest([
            this.getUser$(),
            this.isAuthenticated$,
            this.token$,
          ]);
        })
      );
      // Subscribe to authentication completion observable
      // Response will be an array of user and login status
      authComplete$.subscribe(([user, loggedIn]) => {
        // Redirect to target route after callback processing
        this.router.navigate([targetRoute]);
      });
    }
  }

  logout() {
    // Ensure Auth0 client instance exists
    this.auth0Client$.subscribe((client: Auth0Client) => {
      // Call method to log out
      client.logout({
        client_id: AUTH_CONFIG.clientID,
        returnTo: AUTH_CONFIG.logoutRedirectURL,
        federated: false,
      });
    });
    setTimeout(() => {
      this.cookie.deleteAll();
    }, 500);
  }
}
