interface AuthConfig {
  clientID: string;
  domain: string;
  callbackURL: string;
  logoutURL: string;
  logoutRedirectURL: string;
}

export const AUTH_CONFIG: AuthConfig = {
  clientID: "vAub7W8T5YH2wN6CF02UKpOgjFIz0yJy",
  domain: "siemens-pg-001.eu.auth0.com",
  callbackURL: `${window.location.origin}`,
  logoutURL: "https://siemens-pg-001.eu.auth0.com/v2/logout",
  logoutRedirectURL: `${window.location.origin}`,
};
